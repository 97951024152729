import React, { useState } from 'react';
import CreateNftModal from '../component/CreateNftModal.js';
import { StyleSheet, Text, View, TextInput, Button } from 'react-native';

export const CreateNftScreen = ({ seedPhrase }) => {
    const [text, setText] = useState('');
    const [showCreateNftModal, setShowCreateNftModal] = useState(false);

    const onChangeText = (inputText) => {
        setText(inputText);
    };

    const onSubmit = () => {
        setShowCreateNftModal(true);
    };

    const handleCloseCreateNftModal = () => {
        setShowCreateNftModal(false);
    };

    return (
        <View style={styles.container}>
            <View style={styles.titleContainer}>
                <Text style={styles.title}>Create nft</Text>
            </View>
            <View style={styles.inputContainer}>
                <TextInput
                    style={styles.input}
                    onChangeText={onChangeText}
                    value={text}
                    placeholder="Text"
                    multiline={true}
                />
                <View style={styles.buttonContainer}>
                    <Button title="Confirm" onPress={onSubmit} />
                </View>
            </View>
            <CreateNftModal visible={showCreateNftModal} seedPhrase={seedPhrase} onClose={handleCloseCreateNftModal} dataСreateImages={{ prompt: text, id: 1 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'space-between',
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    inputContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 5,
    },
    input: {
        flex: 1,
        height: 35,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginHorizontal: 5
    },
    buttonContainer: {
        marginRight: 10
    }
});