import AsyncStorage from '@react-native-async-storage/async-storage';

class StorageService {
    async storeData(key, value) {
        try {
            await AsyncStorage.setItem(key, value);
        } catch (error) {
            console.log('Error storeData:', error);
        }
    }

    async getData(key) {
        try {
            const value = await AsyncStorage.getItem(key);
            if (value !== null) {
                return value;
            } else {
                return null;
            }
        } catch (error) {
            console.log('Error getData:', error);
            return null;
        }
    }

    async deleteData(key) {
        try {
            await AsyncStorage.removeItem(key);
        } catch (error) {
            console.log('Error deleteData:', error);
            return null;
        }
    }
}

export default new StorageService();