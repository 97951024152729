import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Toast from 'react-native-toast-message';
import { decrypt } from '../helpers/crypto.js';
import AuthService from '../api/auth/AuthService.js';
import StorageService from '../service/StorageService.js';
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput } from 'react-native';

// Схема валидации
const validationSchema = Yup.object().shape({
    login: Yup.string()
        .email('Введите действительный адрес электронной почты')
        .required('Login is required'),
    password: Yup.string()
        .min(6, 'Пароль должен быть не менее 6 символов')
        .required('Password is required'),
});

export const AuthScreen = ({ navigation }) => {
    const handleLogin = async (values) => {
        try {
            const response = await AuthService.Login(values.login.trim(), values.password.trim());

            if (response.status !== "success") {
                Toast.show({
                    type: 'error',
                    text1: response.error,
                });
                return;
            }

            const { accessToken, refreshToken } = response.data;
            await saveTokens(accessToken, refreshToken); // Сохраняем токены
            // Проверка валидности accessToken
            const verify = await AuthService.verify(accessToken);

            // Если accessToken валиден
            if (verify.status === "success") {
                const { stakingKey } = verify.data;
                const decryptedStakingKey = await decrypt(stakingKey);
                navigation.navigate("Main", { seedPhrase: decryptedStakingKey });
            } else {
                Toast.show({
                    type: 'error',
                    text1: verify.error,
                });
                return;
            }
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: "Произошла ошибка при входе в систему. Пожалуйста, попробуйте еще раз.",
            });
        }
    };

    // Функция для сохранения токенов
    const saveTokens = async (accessToken, refreshToken) => {
        await StorageService.storeData("accessToken", accessToken);
        await StorageService.storeData("refreshToken", refreshToken);
    };

    return (
        <View style={styles.container}>
            <Image source={require('../img/2.png')} style={styles.logo} />
            <Text style={styles.title}>Войдите в свою учетную запись</Text>
            <Text style={styles.subtitle}>
                Безопасный доступ к вашей учетной записи. Управляйте своими цифровыми активами с легкостью — без скрытых комиссий и сюрпризов.
            </Text>
            <Formik
                initialValues={{ login: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleLogin(values)}
            >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                    <>
                        <TextInput
                            style={[styles.input, errors.login && touched.login ? styles.errorInput : null]}
                            onChangeText={handleChange('login')}
                            onBlur={handleBlur('login')}
                            value={values.login}
                            placeholder="Введите свой логин"
                            autoCapitalize="none"
                        />
                        {errors.login && touched.login && (
                            <Text style={styles.errorText}>{errors.login}</Text>
                        )}
                        <TextInput
                            style={[styles.input, errors.password && touched.password ? styles.errorInput : null]}
                            onChangeText={handleChange('password')}
                            onBlur={handleBlur('password')}
                            value={values.password}
                            placeholder="Введите свой пароль"
                            secureTextEntry
                        />
                        {errors.password && touched.password && (
                            <Text style={styles.errorText}>{errors.password}</Text>
                        )}
                        <TouchableOpacity style={styles.button} onPress={handleSubmit}>
                            <Text style={styles.buttonText}>Войти</Text>
                        </TouchableOpacity>
                    </>
                )}
            </Formik>
            <Toast style={{ zIndex: 9999 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        backgroundColor: '#fff',
    },
    logo: {
        width: 150,
        height: 150,
        marginBottom: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 16,
        color: '#666',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        height: 50,
        marginVertical: 10,
        borderWidth: 1,
        padding: 10,
        width: '100%',
        borderRadius: 5,
        borderColor: '#ddd',
        fontSize: 16,
    },
    errorInput: {
        borderColor: 'red',
    },
    errorText: {
        color: 'red',
        fontSize: 14,
        marginBottom: 10,
        alignSelf: 'flex-start',
    },
    button: {
        backgroundColor: '#007bff',
        padding: 15,
        borderRadius: 5,
        width: '100%',
        marginBottom: 10,
    },
    buttonText: {
        fontSize: 18,
        color: '#fff',
        textAlign: 'center',
    },
});