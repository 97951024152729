import axios from 'axios';

class AiService {
    constructor() {
        this.apiUrl = "https://ai.bazerwallet.com";
    }

    async createImages(prompt) {
        try {
            let config = {
                method: 'get',
                url: `${this.apiUrl}/createImages?prompt=${prompt}`,
            };

            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log("AiService.createImages: ", error);
            return { status: "error" };
        }
    }
}

export default new AiService();