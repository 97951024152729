import axios from 'axios';
import { encode } from 'base-64';
import { HttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

class BazerService {
    constructor() {
        this.apiUrl = "https://bazerbot.com/api";
        this.graphqlUrl = 'https://bazerbot.com/api/graphql';
        // this.apiUrl = "http://localhost:3001/api";
        // this.graphqlUrl = 'http://localhost:3001/api/graphql';
    }

    async Login(seedPhrase) {
        try {
            const basicString = encode(`mnemonic:${seedPhrase}:3ZM9nHv7j3`);
            let config = {
                method: 'get',
                url: `${this.apiUrl}/getLogin`,
                headers: {
                    'Authorization': `Basic ${basicString}`
                }
            };

            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log("BazerService.Login: ", error);
            return { status: "error" };
        }
    }

    async createApolloClient(seedPhrase, path) {
        const login = await this.Login(seedPhrase);

        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    authorization: login.token ? `Bearer ${login.token}` : '',
                },
            };
        });

        const httpLink = new HttpLink({
            uri: `${this.graphqlUrl}${path}`
        });

        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
        });
    }

    async UserInfo(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/user");

            const response = await client.query({
                query: gql`
                query InfoUser {
                    infoUser {
                        status
                        data
                        error {
                            message
                        }
                    }
                }`
            });
            return this.apiResponse(response.data.infoUser);
        } catch (error) {
            console.log("BazerService.UserInfo: ", error);
            return { status: "error", data: [] };
        }
    }

    async BalanceList(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/balance");
            const response = await client.query({
                query: gql`
                    query BalanceList {
                        balanceList {
                            status
                            data {
                                name
                                fullName
                                minAmount
                                enabled
                                image
                                price
                                balance
                                address
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.balanceList);
        } catch (error) {
            console.log("BazerService.BalanceList: ", error);
            return { status: "error", data: [] };
        }
    }

    async BalanceNft(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/balance");
            const response = await client.query({
                query: gql`
                    query BalanceList {
                        balanceNft {
                            status
                            data {
                                nftId
                                quantity
                                nftCollection
                                reserve
                                delegationAmount
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.balanceNft);
        } catch (error) {
            console.log("BazerService.BalanceNft: ", error);
            return {
                status: "error",
                data: {
                    nfts: []
                }
            };
        }
    }

    async CreateNft(seedPhrase, data) {
        try {
            const login = await this.Login(seedPhrase);
            let config = {
                method: 'post',
                url: `${this.apiUrl}/wallet/createNftDecimalchain`,
                data: {
                    "coverUrl": data.linkImage,
                    "assetUrl": data.linkImage,

                    "headline": data.headline,
                    "description": data.description,

                    "mnemonics": null,
                    "recipient": null,

                    "transaction": {
                        "network": "mainnet"
                    },

                    "options": {
                        "simulate": false
                    },

                    "payload": {
                        "denom": data.denom,
                        "quantity": data.quantity,
                        "reserveDenom": "del",
                        "reserveAmount": data.reserveAmount,
                        "allowMint": true
                    }
                },
                headers: {
                    'Authorization': `Bearer ${login.token}`
                }
            };

            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log("BazerService.CreateNft: ", error);
            return { status: "error" };
        }
    }

    async ListSell(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/marketplace");
            const response = await client.query({
                query: gql`
                    query ListSell {
                        listSell {
                            status
                            data {
                                userId
                                type
                                productId
                                name
                                price
                                imageUrl
                                description
                                requisites
                                currency
                                collateral
                                downPayment
                                product
                                status
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.listSell);
        } catch (error) {
            console.log("BazerService.listSell: ", error.message);
            return {
                status: "error",
                data: {
                    nfts: []
                }
            };
        }
    }

    async createAdvertisement(seedPhrase, { price, name, description, currency, requisites, paymentMethod, imageUrl, type, product }) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/marketplace");
            const response = await client.query({
                query: gql`
                    query CreateAdvertisement {
                        createAdvertisement(
                            price: ${price}
                            name: "${name}"
                            description: "${description}"
                            currency: "${currency}"
                            requisites: "${requisites}"
                            paymentMethod: "${paymentMethod}"
                            imageUrl: "${imageUrl}"
                            type: "${type}"
                            product: "${product}"
                        ) {
                            status
                            error {
                                message
                            }
                            data {
                                userId
                                type
                                productId
                                name
                                price
                                imageUrl
                                description
                                requisites
                                paymentMethod
                                currency
                                collateral
                                downPayment
                                product
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.createAdvertisement);
        } catch (error) {
            console.log("BazerService.createAdvertisement: ", error);
            return {
                status: "error",
                error: { message: "Ошибка" }
            };
        }
    }

    async BuyProduct(seedPhrase, productId) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/marketplace");
            const response = await client.query({
                query: gql`
                    query Buy {
                        buy(productId: "${productId}") {
                            status
                            data {
                                sellerUrl
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.buy);
        } catch (error) {
            console.log("BazerService.BuyProduct: ", error.message);
            return {
                status: "error",
                error: { message: "Ошибка" }
            };
        }
    }

    async listCommissions(seedPhrase) {
        const login = await this.Login(seedPhrase);
        const listCommissions = await axios({
            method: 'GET',
            timeout: 600000,
            url: `${this.apiUrl}/v2/transferCommission/listCommissions`,
            headers: {
                "Authorization": `Bearer ${login.token}`
            }
        });
        return {
            status: listCommissions.data.status,
            data: listCommissions.data.data,
            error: listCommissions.data.errors,
        }
    }

    async WithdrawalFunds(seedPhrase, amount, address, currency, netBazer, message) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/wallet");
            const response = await client.query({
                query: gql`
                    query WithdrawalFunds {
                            withdrawalFunds(
                                amount: ${amount},
                                address: "${address}",
                                currency: "${currency}",
                                netBazer: ${Boolean(netBazer)}
                                message: "${message}",
                            ) {
                                status
                                data {
                                    txID
                                }
                                error {
                                    translationError
                                }
                            }
                        }`
            });

            return this.apiResponse(response.data.withdrawalFunds);
        } catch (error) {
            console.log(error);
            return {
                status: "error",
                error: { message: "Ошибка" }
            };
        }
    }



    apiResponse(response) {
        return {
            status: response.status,
            data: response.data,
            error: response.error
        };
    }
}

export default new BazerService();