import Loader from '../component/Loader.js';
import React, { useState, useEffect } from 'react';
import BazerService from '../api/bazer/BazerService.js';
import { StyleSheet, Text, View, FlatList, Button, Image, TouchableOpacity, Modal } from 'react-native';

export const NftScreen = ({ seedPhrase }) => {
    const [nftData, setNftData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedNft, setSelectedNft] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);

    // Функция для загрузки данных о NFT
    const loadNftData = async () => {
        try {
            setLoading(true);
            const nftData = await BazerService.BalanceNft(seedPhrase);
            const updatedNftData = []; // Создаем новый массив для обновленных данных NFT

            for (let i = 0; i < nftData.data.length; i++) {
                const infoNft = await BazerService.infoNft(nftData.data[i].nftId);

                // Создаем новый объект с нужными свойствами
                const updatedNft = {
                    ...nftData.data[i], // Копируем все свойства из исходного объекта
                    linkCover: `https://wherebuynft.com/${infoNft.result.cover}`,
                    description: infoNft.result.description,
                    reserve: infoNft.result.totalReserve / 1e18,
                    coinDenom: infoNft.result.coinDenom
                };

                updatedNftData.push(updatedNft); // Добавляем новый объект в массив
            }

            setNftData(updatedNftData); // Устанавливаем обновленные данные в state
        } catch (error) {
            console.error("Error loading NFT data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadNftData();
    }, []);

    // Функция для открытия модального окна с подробной информацией о NFT
    const handleOpenModal = (item) => {
        setSelectedNft(item);
        setModalVisible(true);
    };

    // Функция для закрытия модального окна
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    if (loading) return (<Loader />);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>NFT Balance:</Text>
                <Button title="Refresh" onPress={loadNftData} />
            </View>
            {/* Отображение списка NFT */}
            <FlatList
                data={nftData}
                renderItem={({ item }) => (
                    <TouchableOpacity onPress={() => handleOpenModal(item)}>
                        <View style={styles.item}>
                            <Image
                                style={styles.image}
                                source={{ uri: item.linkCover }}
                            />
                            <View style={styles.textContainer}>
                                <Text style={styles.text}>{item.nftCollection}</Text>
                                <Text style={styles.reserve}>{item ? item.reserve : ''} {item ? item.coinDenom.toUpperCase() : ''}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                )}
                keyExtractor={(item) => item.nftId.toString()}
            />

            {/* Модальное окно с подробной информацией */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={handleCloseModal}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={styles.imageContainer}>
                            <Image
                                style={styles.modalImage}
                                source={{ uri: selectedNft ? selectedNft.linkCover : null }}
                            />
                            <View style={styles.captionContainer}>
                                <Text style={styles.captionText}>{selectedNft ? selectedNft.nftCollection : ''}</Text>
                            </View>
                        </View>

                        <Text style={styles.modalTextDescription}>Description:</Text>
                        <Text style={styles.modalText}>{selectedNft ? selectedNft.description : ''}</Text>
                        <Text style={styles.modalTextReserve}>Reserve: {selectedNft ? selectedNft.reserve : ''} {selectedNft ? selectedNft.coinDenom : ''}</Text>
                        <Text style={styles.delegationAmount}>Delegated: {selectedNft ? selectedNft.delegationAmount : ''} DEL</Text>
                        <Button title="Close" onPress={handleCloseModal} />
                    </View>
                </View>
            </Modal>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: 20,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    item: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        flexDirection: 'row',
        alignItems: 'center',
    },
    image: {
        width: 50,
        height: 50,
        marginRight: 10,
    },
    textContainer: {
        flex: 1,
    },
    text: {
        fontSize: 16,
    },
    reserve: {
        fontSize: 14,
        color: '#888',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        elevation: 5,
        width: '80%', // Ширина модального окна
    },
    imageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    modalImage: {
        width: 100,
        height: 100,
        marginRight: 20,
        borderRadius: 5,
    },
    captionContainer: {
        flex: 1,
    },
    captionText: {
        fontSize: 18,
        fontWeight: 'bold', // Жирный текст для названия
        marginBottom: 10, // Отступ после названия
    },
    modalText: {
        fontSize: 16,
        marginBottom: 10,
    },
    modalTextDescription: {
        fontSize: 14,
        fontWeight: 'bold', // Жирный текст для заголовка описания
        marginBottom: 5,
    },
    modalTextReserve: {
        fontSize: 14,
        fontWeight: 'bold', // Жирный текст для заголовка резерва
        marginBottom: 10,
    },

    delegationAmount: {
        fontSize: 14,
        fontWeight: 'bold', // Жирный текст для заголовка резерва
        marginBottom: 10,
    },
});
