import { gql } from "@apollo/client";
import BazerService from "./BazerService";

class ExchangerBazerService {
    async exchanger(seedPhrase, sumIn, couple) {
        try {
            console.log(sumIn, couple)
            const client = await BazerService.createApolloClient(seedPhrase, "/exchanger");
            const response = await client.query({
                query: gql`
                    query Exchanger {
                        exchanger(sumIn: ${sumIn}, couple: "${couple}") {
                            status
                            data {
                                well
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return BazerService.apiResponse(response.data.exchanger);
        } catch (error) {
            console.log("ExchangerBazerSrvice.exchanger: ", error.message);
            return {
                status: "error",
                error: { message: "Ошибка" }
            };
        }
    }

    async exchangerCalculation(seedPhrase, amount, couple) {
        try {
            const client = await BazerService.createApolloClient(seedPhrase, "/exchanger");
            const response = await client.query({
                query: gql`
                    query ExchangerCalculation {
                        exchangerCalculation(amount: ${amount}, couple: "${couple}") {
                            status
                            data {
                                result
                                price
                                commissionExchanger
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return BazerService.apiResponse(response.data.exchangerCalculation);
        } catch (error) {
            console.log("ExchangerBazerSrvice.exchangerCalculation: ", error.message);
            return {
                status: "error",
                error: { message: "Ошибка" }
            };
        }
    }
}

export default new ExchangerBazerService();