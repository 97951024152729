import React from 'react';
import { Modal, View, Text, TextInput, StyleSheet, Button, Picker, Dimensions } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';

const { height, width } = Dimensions.get('window');

// Схема валидации
const validationSchema = Yup.object().shape({
    type: Yup.string().required('Выберите тип товара'),
    name: Yup.string().required('Введите название товара'),
    price: Yup.number()
        .typeError('Цена должна быть числом')
        .positive('Цена должна быть больше нуля')
        .required('Введите цену'),
    currency: Yup.string().required('Выберите валюту'),
    paymentMethod: Yup.string().required('Выберите метод оплаты'),
    requisites: Yup.string().required('Введите реквизиты'),
    description: Yup.string()
        .required('Описание должно превышать 10 символов')
        .max(300, 'Описание не должно превышать 300 символов'),
    product: Yup.string().required('Укажите ваш товар!'),
});

const SellModal = ({ visible, onClose, onSell, categories }) => {
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Formik
                        initialValues={{
                            type: '',
                            name: '',
                            price: '',
                            currency: '',
                            paymentMethod: '',
                            requisites: '',
                            description: '',
                            product: '',
                            imageUrl: "https://bazerbot.com/images/bazer.png",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            onSell(values);
                        }}
                    >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                            <>
                                <Text style={styles.modalTitle}>Создать новый товар</Text>

                                <Text style={styles.label}>Тип товара:</Text>
                                <Picker
                                    selectedValue={values.type}
                                    onValueChange={(value) => setFieldValue('type', value)}
                                    style={styles.input}
                                >
                                    <Picker.Item label="Выберите тип" value="" />
                                    {categories.map((category) => (
                                        <Picker.Item label={category} value={category} key={category} />
                                    ))}
                                </Picker>
                                <Text style={styles.errorText}>
                                    {touched.type && errors.type ? errors.type : ' '}
                                </Text>

                                <Text style={styles.label}>Название товара:</Text>
                                <TextInput
                                    placeholder="Название"
                                    value={values.name}
                                    onChangeText={handleChange('name')}
                                    onBlur={handleBlur('name')}
                                    style={styles.input}
                                />
                                <Text style={styles.errorText}>
                                    {touched.name && errors.name ? errors.name : ' '}
                                </Text>

                                <Text style={styles.label}>Цена:</Text>
                                <TextInput
                                    placeholder="Цена"
                                    value={values.price}
                                    onChangeText={handleChange('price')}
                                    onBlur={handleBlur('price')}
                                    keyboardType="numeric"
                                    style={styles.input}
                                />
                                <Text style={styles.errorText}>
                                    {touched.price && errors.price ? errors.price : ' '}
                                </Text>

                                <Text style={styles.label}>Валюта:</Text>
                                <Picker
                                    selectedValue={values.currency}
                                    onValueChange={(value) => setFieldValue('currency', value)}
                                    style={styles.input}
                                >
                                    <Picker.Item label="Выберите валюту" value="" />
                                    {['UAH', 'RUB', 'USDT', 'TRON'].map((currency) => (
                                        <Picker.Item label={currency} value={currency} key={currency} />
                                    ))}
                                </Picker>
                                <Text style={styles.errorText}>
                                    {touched.currency && errors.currency ? errors.currency : ' '}
                                </Text>

                                <Text style={styles.label}>Метод оплаты:</Text>
                                <Picker
                                    selectedValue={values.paymentMethod}
                                    onValueChange={(value) => setFieldValue('paymentMethod', value)}
                                    style={styles.input}
                                >
                                    <Picker.Item label="Выберите метод оплаты" value="" />
                                    {['mono', 'trc20', 'privat24'].map((method) => (
                                        <Picker.Item label={method} value={method} key={method} />
                                    ))}
                                </Picker>
                                <Text style={styles.errorText}>
                                    {touched.paymentMethod && errors.paymentMethod ? errors.paymentMethod : ' '}
                                </Text>

                                <Text style={styles.label}>Реквизиты:</Text>
                                <TextInput
                                    placeholder="Реквизиты"
                                    value={values.requisites}
                                    onChangeText={handleChange('requisites')}
                                    onBlur={handleBlur('requisites')}
                                    style={styles.input}
                                />
                                <Text style={styles.errorText}>
                                    {touched.requisites && errors.requisites ? errors.requisites : ' '}
                                </Text>

                                <Text style={styles.label}>Описание:</Text>
                                <TextInput
                                    placeholder="Описание"
                                    value={values.description}
                                    onChangeText={handleChange('description')}
                                    onBlur={handleBlur('description')}
                                    style={styles.input}
                                />
                                <Text style={styles.errorText}>
                                    {touched.description && errors.description ? errors.description : ' '}
                                </Text>

                                <Text style={styles.label}>Ваш товар:</Text>
                                <TextInput
                                    placeholder="Ваш товар"
                                    value={values.product}
                                    onChangeText={handleChange('product')}
                                    onBlur={handleBlur('product')}
                                    style={styles.input}
                                />
                                <Text style={styles.errorText}>
                                    {touched.product && errors.product ? errors.product : ' '}
                                </Text>

                                <View style={styles.buttonContainer}>
                                    <Button title="Отмена" onPress={onClose} color="red" />
                                    <Button title="Выставить" onPress={handleSubmit} />
                                </View>
                            </>
                        )}
                    </Formik>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        borderRadius: 8,
        width: width * 0.9,
        height: height * 0.9,
        padding: 16,
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    label: {
        fontSize: 14,
        marginBottom: 5,
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 10,
        marginBottom: 5,
    },
    errorText: {
        height: 14, // Резервируем место для ошибок
        fontSize: 12,
        color: 'red',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
});

export default SellModal;