class AuthService {
    constructor() {
        this.apiUrl = "https://auth.bazerwallet.com";
    }

    async Login(email, password) {
        try {
            const response = await fetch(`${this.apiUrl}/auth/login?email=${email}&password=${password}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            return this.apiResponse(data);
        } catch (error) {
            console.log("AuthService.Login: ", error);
            return { status: "error", message: error.message };
        }
    }

    async verify(accessToken) {
        try {
            const response = await fetch(`${this.apiUrl}/auth/verify?accessToken=${accessToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            return this.apiResponse(data);
        } catch (error) {
            console.log("AuthService.verify: ", error);
            return { status: "error", message: error.message };
        }
    }

    async updateToken(refreshToken) {
        try {
            const response = await fetch(`${this.apiUrl}/auth/updateToken?refreshToken=${refreshToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            return this.apiResponse(data);
        } catch (error) {
            console.log("AuthService.updateToken: ", error);
            return { status: "error", message: error.message };
        }
    }

    apiResponse(response) {
        return {
            status: response.status,
            data: response.data,
            error: response.error
        };
    }
}

export default new AuthService();