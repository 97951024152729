import { Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { AuthScreen } from '../screen/AuthScreen.js';
import { CabinetScreen } from '../screen/CabinetScreen.js';
import { SettingScreen } from '../screen/SettingScreen.js';
import { CreateNftScreen } from '../screen/CreateNftScreen.js'

import Loader from './Loader.js';
import { decrypt } from '../helpers/crypto.js';
import { NftScreen } from '../screen/NftScreen.js';
import AuthService from '../api/auth/AuthService.js';
import StorageService from '../service/StorageService.js';
import MarketplaceScreen from '../screen/MarketplaceScreen.js';
import ExchangerScreen from '../screen/ExchangerScreen.js';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function Navigation() {
    const [loginStatus, setIsLoggedIn] = useState(null);
    const [mnemonic, setMnemonic] = useState("");
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                // Получение токенов
                const accessToken = await StorageService.getData('accessToken');
                const refreshToken = await StorageService.getData('refreshToken');

                if (!accessToken) return; // Нет accessToken, выход

                // Проверка валидности accessToken
                const verify = await AuthService.verify(accessToken);

                // Если accessToken валиден
                if (verify.status === "success") {
                    const { stakingKey } = verify.data;

                    if (!stakingKey) { // Если нет stakingKey
                        await clearTokens();
                        return;
                    }

                    setIsLoggedIn(true);
                    const decryptedStakingKey = await decrypt(stakingKey);
                    setMnemonic(decryptedStakingKey);
                }
                // Если accessToken невалиден, пробуем обновить токен
                else if (refreshToken) {
                    const isTokenUpdated = await refreshAccessToken(refreshToken);
                    if (isTokenUpdated) await checkLoginStatus(); // Рекурсивный вызов после обновления
                }
            } catch (error) {
                console.error('Error reading login status from AsyncStorage:', error);
            } finally {
                setLoading(false); // Гарантированное завершение загрузки
            }
        };

        // Функция для очистки токенов из хранилища
        const clearTokens = async () => {
            await StorageService.deleteData("accessToken");
            await StorageService.deleteData("refreshToken");
        };

        // Функция для обновления токена
        const refreshAccessToken = async (refreshToken) => {
            const updateToken = await AuthService.updateToken(refreshToken);
            if (updateToken.status === "success") {
                await StorageService.storeData("accessToken", updateToken.data.accessToken);
                return true; // Успешное обновление
            }
            return false; // Не удалось обновить
        };

        checkLoginStatus();
    }, []);

    if (loading) return (<Loader />);

    const MainNavigator = ({ seedPhrase, route }) => {
        const finalSeedPhrase = seedPhrase || route.params?.seedPhrase;
        return (
            <Tab.Navigator>
                {/* <Tab.Screen name="createNft" options={{
                    headerTitleAlign: 'center',
                    title: 'Create Nft',
                    tabBarIcon: ({ color, size }) => (
                        <Text style={{ color: color, fontSize: size }}>{'\u2B1C'}</Text>
                    ),
                }}>
                    {props => <CreateNftScreen {...props} seedPhrase={finalSeedPhrase} />}
                </Tab.Screen>
                <Tab.Screen name="nft" options={{
                    headerTitleAlign: 'center',
                    title: 'Nft',
                    tabBarIcon: ({ color, size }) => (
                        <Text style={{ color: color, fontSize: size }}>{'\u2B1C'}</Text>
                    ),
                }}>
                    {props => <NftScreen {...props} seedPhrase={finalSeedPhrase} />}
                </Tab.Screen> */}
                <Tab.Screen name="cabinet" options={{
                    headerTitleAlign: 'center',
                    title: 'Cabinet',
                    tabBarIcon: ({ color, size }) => (
                        <Text style={{ color: color, fontSize: size }}>{'\u2B1C'}</Text>
                    ),
                }}>
                    {props => <CabinetScreen {...props} seedPhrase={finalSeedPhrase} />}
                </Tab.Screen>
                <Tab.Screen name="exchanger" options={{
                    headerTitleAlign: 'center',
                    title: 'Exchanger',
                    tabBarIcon: ({ color, size }) => (
                        <Text style={{ color: color, fontSize: size }}>{'\u2B1C'}</Text>
                    ),
                }}>
                    {props => <ExchangerScreen {...props} seedPhrase={finalSeedPhrase} />}
                </Tab.Screen>
                <Tab.Screen name="marketplace" options={{
                    headerTitleAlign: 'center',
                    title: 'Marketplace',
                    tabBarIcon: ({ color, size }) => (
                        <Text style={{ color: color, fontSize: size }}>{'\u2B1C'}</Text>
                    ),
                }}>
                    {props => <MarketplaceScreen {...props} seedPhrase={finalSeedPhrase} />}
                </Tab.Screen>
                <Tab.Screen name="setting" options={{
                    headerTitleAlign: 'center',
                    title: 'Setting',
                    tabBarIcon: ({ color, size }) => (
                        <Text style={{ color: color, fontSize: size }}>{'\u2B1C'}</Text>
                    ),
                }}>
                    {props => <SettingScreen {...props} seedPhrase={finalSeedPhrase} />}
                </Tab.Screen>
            </Tab.Navigator>
        );
    };

    return (
        <View style={{ flex: 1 }}> {/* Обертка, чтобы содержимое не выходило за пределы */}
            <NavigationContainer>
                <Stack.Navigator initialRouteName={loginStatus ? "Main" : "Auth"} screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="Main">
                        {props => <MainNavigator {...props} seedPhrase={mnemonic} />}
                    </Stack.Screen>
                    <Stack.Screen name="Auth" component={AuthScreen} />
                </Stack.Navigator>
            </NavigationContainer>
        </View>
    );
}

export default Navigation;