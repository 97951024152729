import CryptoJS from 'crypto-js';

// Функция для расшифровки данных
export async function decrypt(encryptedText, key = "12345678901234567890123456789012") {
    try {
        const [ivString, encrypted] = encryptedText.split(':');
        const iv = CryptoJS.enc.Base64.parse(ivString);  // Декодируем IV из base64
        const bytes = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Utf8.parse(key), {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7
        });
        // Преобразуем в строку
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('Decryption error:', error);
        throw new Error('Failed to decrypt data');
    }
}