import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Button } from 'react-native';

import BazerService from '../api/bazer/BazerService.js';
import StorageService from '../service/StorageService.js';

export const SettingScreen = ({ seedPhrase, navigation }) => {
    const [email, setEmail] = useState(null);
    const [telegramId, setTelegramId] = useState(null);

    useEffect(() => {
        const getInfo = async () => {
            try {
                const response = await BazerService.UserInfo(seedPhrase);
                setEmail(response.data.user.email);
                setTelegramId(response.data.user.id_telegram);
            } catch (error) {
                console.error('Error getting email and Telegram ID:', error);
            }
        };

        getInfo();
    }, []);

    const logout = () => {
        navigation.navigate("Auth");
        StorageService.deleteData('accessToken');
        StorageService.deleteData('refreshToken');
    };

    return (
        <View style={styles.container}>
            <View style={styles.section}>
                <Text style={styles.sectionTitle}>Account Management</Text>
            </View>
            <View style={styles.rightContent}>
                {email && <Text style={styles.info}>Email: {email}</Text>}
                {telegramId && <Text style={styles.info}>Telegram ID: {telegramId}</Text>}
                <View style={styles.buttonContainer}>
                    <Button title="Logout" onPress={logout} />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#fff',
    },
    section: {
        alignItems: 'center',
    },
    sectionTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    rightContent: {
        alignItems: 'flex-start',
    },
    info: {
        fontSize: 16,
        marginTop: 10,
        textAlign: 'left',
    },
    buttonContainer: {
        marginTop: 10,
    },
});
