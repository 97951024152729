import Loader from '../component/Loader';
import Toast from 'react-native-toast-message';
import SellModal from '../component/SellModal';
import React, { useState, useEffect } from 'react';
import BazerService from '../api/bazer/BazerService';
import ProductDetailsModal from '../component/ProductDetailsModal';
import { StyleSheet, View, Text, FlatList, Image, TouchableOpacity, Modal, Button, Picker } from 'react-native';

const MarketplaceScreen = ({ seedPhrase }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sellModalVisible, setSellModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalVisibleDetails, setModalVisibleDetails] = useState(false);

    const categories = [
        "staking-share"
    ];

    // Загрузка товаров и категорий
    const fetchItems = async () => {
        try {
            setLoading(true);
            const response = await BazerService.ListSell(seedPhrase);
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);

    // Функция для открытия модального окна продажи
    const openSellModal = () => {
        setSellModalVisible(true);
    };

    // Функция для закрытия модального окна продажи
    const closeSellModal = () => {
        setSellModalVisible(false);
    };

    // Функция для обработки продажи товара
    const handleSellItem = async (values) => {
        try {
            const sellItemResponse = await BazerService.createAdvertisement(seedPhrase, values);
            if (sellItemResponse.status === 'OK') {
                Toast.show({
                    type: 'success',
                    text1: 'Товар успешно выставлен на продажу!',
                });
                fetchItems();
            } else {
                Toast.show({
                    type: 'error',
                    text1: sellItemResponse.error.message,
                });
            }
        } catch (error) {
            console.error('Error selling item:', error);
        } finally {
            closeSellModal();
        }
    };

    // Функция для обработки покупки товара
    const handleBuyProduct = async (productId) => {
        try {
            const buyProductResponse = await BazerService.BuyProduct(seedPhrase, productId);
            if (buyProductResponse.status === 'OK') {
                Toast.show({
                    type: 'success',
                    text1: 'Перейдите в bazerbot для дальнейшего совершения сделки!',
                });
                fetchItems();
            } else {
                Toast.show({
                    type: 'error',
                    text1: buyProductResponse.error.message,
                });
            }
        } catch (error) {
            console.error('Error selling item:', error);
        } finally {
            closeModalDetails();
        }
    };

    const handleItemPress = (item) => {
        setSelectedItem(item);
        setModalVisibleDetails(true);
    };

    const closeModalDetails = () => {
        setSelectedItem(null);
        setModalVisibleDetails(false);
    };

    // Рендеринг списка товаров
    const renderItemsByCategory = () => (
        <FlatList
            data={items.filter(item => !selectedCategory || item.type === selectedCategory)}
            renderItem={({ item }) => (
                <TouchableOpacity onPress={() => handleItemPress(item)}>
                    <View style={styles.item}>
                        <Image source={{ uri: item.imageUrl }} style={styles.itemImage} />
                        <View style={styles.itemDetails}>
                            <Text style={styles.itemName}>{item.name}</Text>
                        </View>
                        <Text style={styles.itemPrice}>
                            {item.price} {item.currency}
                        </Text>
                    </View>
                </TouchableOpacity>
            )}
            keyExtractor={(item) => item.productId}
        />
    );

    return (
        <View style={styles.container}>
            <Text style={styles.label}>Тип товара:</Text>
            <Picker
                selectedValue={selectedCategory}
                onValueChange={(value) => setSelectedCategory(value)}
                style={styles.input}
            >
                <Picker.Item label="Выберите тип" value="" />
                {categories.map((category) => (
                    <Picker.Item label={category} value={category} key={category} />
                ))}
            </Picker>

            {loading ? <Loader /> : renderItemsByCategory()}

            {/* Модальное окно для продажи */}
            <SellModal
                visible={sellModalVisible}
                onClose={closeSellModal}
                onSell={handleSellItem}
                categories={categories}
            />

            {/* Модальное окно для отображения деталей */}
            <ProductDetailsModal
                modalVisible={modalVisibleDetails}
                selectedItem={selectedItem}
                closeModal={closeModalDetails}
                buyProduct={handleBuyProduct}
            />

            {/* Кнопка для открытия модального окна продажи */}
            <TouchableOpacity style={styles.fab} onPress={openSellModal}>
                <Text style={styles.fabText}>+</Text>
            </TouchableOpacity>
            <Toast style={{ zIndex: 9999 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f0f0f0',
        padding: 20,
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 10,
        padding: 10,
    },
    itemImage: {
        width: 50,
        height: 50,
        borderRadius: 8,
        marginRight: 10,
    },
    itemDetails: {
        flex: 1, // Занимает оставшееся пространство
    },
    itemName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    itemPrice: {
        fontSize: 14,
        color: '#4CAF50',
        textAlign: 'right', // Дополнительно на случай текста
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 8,
        marginBottom: 10,
    },
    fab: {
        position: 'absolute',
        right: 20,
        bottom: 20,
        width: 60,
        height: 60,
        borderRadius: 30,
        backgroundColor: '#007bff',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fabText: {
        fontSize: 30,
        color: 'white',
    },

});

export default MarketplaceScreen;