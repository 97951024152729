import _ from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React, { useState } from 'react';
import Loader from '../component/Loader';
import Toast from 'react-native-toast-message';
import { Picker } from '@react-native-picker/picker';
import ExchangerBazerService from '../api/bazer/ExchangerBazerService';
import { View, Text, TextInput, Button, StyleSheet } from 'react-native';

const ExchangerScreen = ({ seedPhrase }) => {
    const [loading, setLoading] = useState(false);
    const [exchangeDetails, setExchangeDetails] = useState({
        result: null,
        price: null,
        commissionExchanger: null,
    });

    const currencies = [
        'usdt', 'del', 'bzr', 'tron', 'btc', 'etc', 'ton', 'artery', 'mpx', 'xfi', 'xch', 'bip', 'hub', 'usdtbsc', 'cashback', 'cashbsc',
    ];

    const validationSchema = Yup.object({
        fromCurrency: Yup.string().required('Выберите исходную валюту'),
        toCurrency: Yup.string().required('Выберите целевую валюту'),
        amount: Yup.number()
            .required('Введите сумму')
            .positive('Сумма должна быть больше нуля')
            .typeError('Сумма должна быть числом'),
    });

    const fetchExchangerCalculation = async (amount, pair) => {
        try {
            setLoading(true);
            const response = await ExchangerBazerService.exchangerCalculation(
                seedPhrase,
                amount,
                pair
            );
            if (response && response.status === 'OK') {
                setExchangeDetails({
                    result: response.data.result,
                    price: response.data.price,
                    commissionExchanger: response.data.commissionExchanger,
                });
            } else {
                Toast.show({
                    type: 'error',
                    text1: response.error.message,
                });
                setExchangeDetails({ result: null, price: null, commissionExchanger: null });
            }
        } catch (error) {
            console.error('Ошибка расчёта обмена:', error);
            setExchangeDetails({ result: null, price: null, commissionExchanger: null });
        } finally {
            setLoading(false);
        }
    };

    const fetchExchanger = async (amount, pair) => {
        try {
            const response = await ExchangerBazerService.exchanger(
                seedPhrase,
                amount,
                pair
            );

            if (response.status === 'OK') {
                Toast.show({
                    type: 'success',
                    text1: 'Обмен успешно завершен!',
                });
            } else {
                Toast.show({
                    type: 'error',
                    text1: response.error.message,
                });
            }
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: "Ошибка обмена!",
            });
        }
    };

    const calculateExchange = _.debounce((amount, pair) => {
        if (amount && pair) {
            fetchExchangerCalculation(amount, pair);
        } else {
            setExchangeDetails({ result: null, price: null, commissionExchanger: null });
        }
    }, 300);

    const handleCurrencyChange = (field, itemValue, values, setFieldValue) => {
        // Обновляем валюту
        setFieldValue(field, itemValue);

        // Проверка, чтобы одна и та же валюта не была выбрана в обоих списках
        if (field === 'fromCurrency' && itemValue === values.toCurrency) {
            // Если исходная валюта и целевая совпадают, сбрасываем целевую валюту
            setFieldValue('toCurrency', currencies[0]);
        } else if (field === 'toCurrency' && itemValue === values.fromCurrency) {
            // Если целевая валюта и исходная совпадают, сбрасываем исходную валюту
            setFieldValue('fromCurrency', currencies[0]);
        }

        // Перезапуск расчета обмена
        calculateExchange(values.amount, `${values.fromCurrency}_to_${values.toCurrency}`);
    };

    return (
        <Formik
            initialValues={{
                fromCurrency: currencies[0],
                toCurrency: currencies[1],
                amount: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const pair = `${values.fromCurrency}_to_${values.toCurrency}`;
                fetchExchanger(values.amount, pair);
            }}
        >
            {({ handleChange, handleBlur, handleSubmit, values, setFieldValue, errors, touched }) => {
                return (
                    <View style={styles.container}>
                        <Text style={styles.label}>Исходная валюта:</Text>
                        <Picker
                            selectedValue={values.fromCurrency}
                            onValueChange={(itemValue) => handleCurrencyChange('fromCurrency', itemValue, values, setFieldValue)}
                            style={styles.picker}
                        >
                            {currencies.map((currency) => (
                                <Picker.Item key={currency} label={currency} value={currency} />
                            ))}
                        </Picker>
                        {touched.fromCurrency && errors.fromCurrency && (
                            <Text style={styles.error}>{errors.fromCurrency}</Text>
                        )}

                        <Text style={styles.label}>Целевая валюта:</Text>
                        <Picker
                            selectedValue={values.toCurrency}
                            onValueChange={(itemValue) => handleCurrencyChange('toCurrency', itemValue, values, setFieldValue)}
                            style={styles.picker}
                        >
                            {currencies.map((currency) => (
                                <Picker.Item key={currency} label={currency} value={currency} />
                            ))}
                        </Picker>
                        {touched.toCurrency && errors.toCurrency && (
                            <Text style={styles.error}>{errors.toCurrency}</Text>
                        )}

                        <Text style={styles.label}>Сумма для обмена:</Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Введите сумму"
                            keyboardType="numeric"
                            onChangeText={(text) => {
                                handleChange('amount')(text);
                                calculateExchange(text, `${values.fromCurrency}_to_${values.toCurrency}`);
                            }}
                            onBlur={handleBlur('amount')}
                            value={values.amount}
                        />
                        {touched.amount && errors.amount && (
                            <Text style={styles.error}>{errors.amount}</Text>
                        )}

                        {/* Информация об обмене */}
                        {loading && <Loader />}
                        {exchangeDetails.price && (
                            <View style={styles.infoBox}>
                                <Text style={styles.info}>
                                    Курс обмена: 1 {values.fromCurrency} = {exchangeDetails.price} {values.toCurrency}
                                </Text>
                                <Text style={styles.info}>
                                    Вы отдаете: {values.amount} {values.fromCurrency}
                                </Text>
                                <Text style={styles.info}>
                                    Вы получите: {exchangeDetails.result} {values.toCurrency}
                                </Text>
                                <Text style={styles.info}>
                                    Комиссия обменника: {exchangeDetails.commissionExchanger} {values.toCurrency}
                                </Text>
                            </View>
                        )}

                        <Button onPress={handleSubmit} title="Обменять" />
                        <Toast style={{ zIndex: 9999 }} />
                    </View>
                );
            }}
        </Formik>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    label: {
        fontSize: 16,
        marginBottom: 8,
    },
    picker: {
        height: 50,
        marginBottom: 16,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 8,
        padding: 8,
        marginBottom: 16,
    },
    error: {
        color: 'red',
        marginBottom: 8,
    },
    infoBox: {
        backgroundColor: '#f9f9f9',
        padding: 16,
        borderRadius: 8,
        marginBottom: 16,
    },
    info: {
        fontSize: 16,
        color: 'green',
    },
});

export default ExchangerScreen;